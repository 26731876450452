<template>
  <div>
    <WsCreate
      :modelName="modelName"
      :label="label"
      :fields="fields"
      :primary="_primary"
      v-model="state"
      :emitInput="true"
    >
    </WsCreate>
  </div>
</template>

<script>
import model from "@/__vue2stone_cms/models/service_plan_item";
export default {
  metaInfo() {
    return {
      title: `${this.$t("新增")} ${this.$t(this.label)}`,
    };
  },
  watch: {
    "state.type": {
      handler() {
        this.state.items = null;
        this.state.unit = null;
      },
    },
  },
  computed: {
    _primary() {
      const fields = ["name", "type"];
      if (this.state && this.state.type) {
        const type = this.state.type;
        if (type == "custom-items") {
          fields.push("items");
        }
        if (type == "count") {
          fields.push("unit");
        }
      }
      return [
        {
          type: "stateCard",
          floors: [
            {
              fields: fields,
            },
          ],
        },
      ];
    },
  },
  data() {
    return {
      state: null,
      ...model,
    };
  },
};
</script>

<style>
</style>